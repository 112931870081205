<template>
  <section class="cont create-douyin-box">
     <!-- 面包屑  -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>营销云</el-breadcrumb-item>
           <el-breadcrumb-item>运营</el-breadcrumb-item>
           <el-breadcrumb-item to="/activity_list/DOUYIN">抖音作品发布</el-breadcrumb-item>
           <el-breadcrumb-item>抖音作品发布</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button class="bg-gradient" @click="handleSave('DRAFT')">保存草稿</el-button>
     </el-row>
     <!-- 返回页头 -->
     <el-page-header @back="goBack" content="发布抖音作品"></el-page-header>


      <el-row class="content">
          <el-row class="step-cont step1-cont" >
        <el-row class="input-box">
          <el-col :span="2" class="input-name"><i class="asterisk">*</i>作品标题</el-col>
          <el-col :span="9" class="m-left-10">
            <el-input  class="width-300" v-model="eventName"></el-input>
          </el-col>
        </el-row>
        <el-row class="input-box">
          <el-col :span="2" class="input-name"><i class="asterisk">*</i>发送方式</el-col>
          <el-col :span="9" class="m-left-10">
            <el-radio v-model="pushtype" label="1">立即发送</el-radio>
          </el-col>
        </el-row>
        <el-row class="input-box">
          <el-col :span="2" class="input-name"><i class="asterisk" style="opacity: 0">*</i></el-col>
          <el-col :span="9" class="m-left-10">
            <el-radio v-model="pushtype" label="2">定时发送</el-radio>
            <el-date-picker
                v-if="pushtype == 2"
                style="width: 11.6rem"
                v-model="timeCycle"
                :picker-options="pickerOptions"
                @change="handchangeDate"
                type="datetime"

                placeholder="选择日期时间">
            </el-date-picker>
          </el-col>
        </el-row>

        <el-row class="input-box" v-if="datalist.length == 0 ">
          <el-col :span="2" class="input-name"><i class="asterisk">*</i>选择素材</el-col>
          <el-col :span="9" class="m-left-10" @click.native="addmaterial()">
            <div class="uploadbox">
              <p class="uploadicon">+</p>
              <p>选择素材</p>
            </div>
          </el-col>
        </el-row>


        <!-- 回显图片-->

        <el-row class="input-box" v-if="datalist.length != 0 ">
          <el-col :span="2" class="input-name"><i class="asterisk" style="opacity: 0;">*</i></el-col>
          <el-col :span="9" class="m-left-10">
            <img :src="datalist[0].path" :onerror="errorImg01" style="width: 145px;height: 145px;cursor: pointer;" v-if="datalist[0].type === 'IMAGE' " />
            <video :src="datalist[0].path" style="width: 145px;height: 145px;cursor: pointer;"  controls v-if="datalist[0].type === 'VIDEO' " />
          </el-col>
        </el-row>
        <Popup v-if="centerDialogVisible"
               @getdatalist="getdatalist"
               :types="types"
               :visible.sync="centerDialogVisible"
               ref="popup"></Popup>

        <el-row class="next-btn m-top-15">
          <!--            <el-button  type="primary" class="m-left-30" @click="nextStep" v-show="active !== 3">下一步</el-button>-->
          <el-button  type="primary" class="m-left-30 bg-gradient" @click="handleSave('')" v-show="active === 0">发送</el-button>
        </el-row>
      </el-row>
      </el-row>
  </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import { operate } from  '@/api/interface/smtech'
import Popup from  '@/components/local/Popup.vue'
import { mapState } from 'vuex'
export default {
  watch: {
    hotelInfo: {
        handler(newVal, oldVal) {
          if (newVal.id !== oldVal.id && oldVal) {
            this.hotelId = this.hotelInfo.id
          }
        },
    }
  },
  computed: {
    ...mapState(['hotelInfo']),
    handleRule() {
      return this.isWaitSend === 'NO' ? '立即发送' : '定时发送'
    }
  },
  components: {Popup},
  data() {
    return {
      //防止选中过去的日期
      pickerOptions: {
        disabledDate(time) {
          //如果没有后面的-8.64e7就是不可以选择今天的
          return time.getTime() < Date.now() -1 * 24 * 3600 * 1000;
        },
      },
      datalist:[],
      active: 0,
      id: 0,
      hotelId:'',
      pushtype:'1',
      types:'',//子组件上传的类型
      centerDialogVisible:false,
      code:'',
      companyId:'',
      tabPosition:'left',
      accessToken:'',
      errorImg01:'this.src="' + require('../../../../assets/advert_template_img.png') + '"',
      /* 第一步 */
      eventName: '',    // 活动名称
      timeCycle:'',    // 周期时间段
      isConvert: 'NO',  // 转化跟踪
      days: '',         // 转化周期
      targetId: '',     // 转化目标
      targetList: [
        {},
      ],   // 转化目标列表
      /* 第二步 */
      sendRules: [
        { value: 'NO',  label: '立即发送' },
        { value: 'YES', label: '定时发送' }
      ],
      isWaitSend: 'NO', // 发送规则
      /* 第三步 */
      limit: 0,
      page: 1,
      videoList: [],
      videoId: '',
      title: '',
      sendCont: '1'
    }
  },
  comments:{
    Popup
  },
  created() {

    this.accessToken = sessionStorage.getItem('accessToken')
    if (this.$route.query.code){
      this.code = this.$route.query.code
      this.getdytoken()
    }
  },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.action = this.$route.query.act
    this.action === 'edit' && this.getTiktokObj()
  },
  methods: {
    // 获取抖音编辑信息
    getTiktokObj(){
      let tiktokObj = JSON.parse(sessionStorage.getItem('activityObj'))
      this.id = tiktokObj.id
      this.eventName = tiktokObj.eventName
      this.timeCycle.push(tiktokObj.beginDate, tiktokObj.endDate)
      this.isConvert = tiktokObj.isConvert
      this.targetId = tiktokObj.targetId
      this.videoId = tiktokObj.content
    },
    //保存草稿
    SaveDrafts(){
      let url = operate.createActivity
      let param = new FormData()
      param.append('hotelId', this.hotelInfo.id)
      param.append('companyId', this.companyId)
      param.append('eventName', this.eventName)
      param.append('beginDate', this.timeCycle[0])
      param.append('endDate', this.timeCycle[1])
      param.append('isConvert', this.isConvert)
      param.append('targetId', this.targetId)
      param.append('isWaitSend', 'NO')
      param.append('content', this.videoId)
      param.append('groupIds', this.videoId)
      param.append('state', 'END')
      param.append('platform', 'DOUYIN')
      param.append('pushRule', 'ONCE')
      this.$axios.post(url, param, 'file').then((res) => {
        if (res.status === 200) {
          this.$message({
            message: "创建成功!",
            type: "success",
          });
          this.$router.push("/activity_list/DOUYIN");
        }
      });
    },
    //防止选中过去的时间
    handchangeDate() {//当前时间1分钟后
      var startAt = new Date(this.timeCycle) * 1000 / 1000;
      if (startAt < Date.now()) {
        this.timeCycle = new Date() * 1 + 60 * 1000;
      }
    },
    // 保存发布
    handleSave(type){
      let url = operate.createTiktok
      let param = new FormData()
      param.append('hotelId', this.hotelInfo.id)
      param.append('companyId', this.companyId)
      param.append('eventName', this.eventName)
      param.append('beginDate', this.timeCycle[0])
      param.append('endDate', this.timeCycle[1])
      param.append('isConvert', this.isConvert)
      param.append('targetId', this.targetId)
      param.append('isWaitSend', 'NO')
      param.append('content', this.videoId)
      if (type == 'END'){
        param.append('state', 'END')
      }else{
        param.append('state', 'DRAFT')
      }
      param.append('platform', 'DOUYIN')
      param.append('pushRule', 'ONCE')
      if (this.action === 'edit') {
        url = operate.editActivity
        param.append('id', this.id)
      }
      this.$axios.post(url, param, 'file').then(res => {

        if (res.success) {
          this.$message({
            showClose: true,
            message: '创建成功！',
            type: 'success'
          })
          this.goBack()
        }
      }).catch(err => {
        let status = err.response.data.status
        if (status === 10007) this.getTiktokCode()
      })
    },
    getTiktokCode(){
      const url = operate.tiktokCode
      const param = { hotelId: this.hotelInfo.id,accessToken: this.accessToken }
      this.$axios.get(url, param).then(res => {

      }).catch(err => {
        let href = 'https://open.douyin.com/platform/oauth/connect?client_key=awc8nehl6rg01yee&redirect_uri=' +
            'https://hosmart.hored.net/create_douyin?state=dayizhu&scope=video.create,video.delete,video.data,video.list,' +
            'video.search,video.search.comment,user_info,following.list,fans.list,login_id,renew_refresh_token,' +
            'mobile,mobile_alert,video.comment,item.comment,data.external.user,data.external.item,fans.data,' +
            'star_top_score_display,star_tops,star_author_score_display,discovery.ent,hotsearch,data.external.' +
            'sdk_share,poi.search,micapp.is_legal,im&response_type=code'
        // window.open(href,'_blank') // 在新窗口打开外链接
        window.location.href = href
      })
    },
    //获取抖音平台的token
    getdytoken(){
      let url = operate.getAccessToken
      const param = {
        code:this.code,
        hotelId:this.hotelInfo.id
      }
      this.$axios.post(url, param, 'json').then(res => {
        // if (res.success) this.targetList = res.records
      })

    },
    /* 第一步 */
    // 获取转化目标列表
    getTargetList(category){
      const url = urlObj.metadataList
      const param = { category }
      this.$axios.post(url, param).then(res => {
        if (res.success) this.targetList = res.records
      })
    },


    changeVideo(videoId){
      this.title = this.videoList.filter(item => {
        return item.id === videoId
      })[0].title
    },
    // 下一步
    nextStep(){
      // 第一步
      if (!this.eventName) {
        this.$alert('活动名称不能为空！', '', {
          confirmButtonText: this.confirm,
          type: 'error'
        })
        return
      }
      if (this.timeCycle.length === 0){
        this.$alert('活动时间不能为空！', '', {
          confirmButtonText: this.confirm,
          type: 'error'
        })
        return
      }
      // 第三步
      if (this.active === 2) {
        if (!this.videoId) {
          this.$alert('选择视频不能为空！', '', {
            confirmButtonText: this.confirm,
            type: 'error'
          })
          return
        }
      }
      this.active ++
    },
    //接收子组件传过来的值
    getdatalist(parmary){
      this.datalist.push(parmary)
      this.types = this.datalist[0].type
      console.log(this.types)
    },
    addmaterial(){
      this.centerDialogVisible = true

    },
    // 返回上一页
    goBack(){
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="scss" scoped>
//.el-container .el-main .advert{ background-color: #ffffff }
.title-bar{
  line-height: 60px; padding: 0 20px; background-color: #f7f7f8;
  h2 { float: left;font-size: 18px;font-weight: normal }
  .btn{
    float: right;
  }
}
.step-box{
  border-bottom: 1px solid #e4e4e4;
  .step-wrapper{
    padding: 10px 0 10px 20px;
    /deep/ .el-step__title{ font-size: 12px }
  }
}
.content{
  font-size: 13px;background: #FFFFFF;
  .asterisk { color: red; margin-right: 3px }
  .input-name { text-align: right }
  .step-cont{ width: 830px; padding: 30px 0 0 40px }
  .step1-cont{
    .input-box{ line-height: 28px; margin-bottom: 20px }
  }



  .next-btn { margin: 0 0 40px 40px }
}
.uploadbox{
  width: 100px;
  height: 100px;
  background: #e4e7ed;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.uploadicon{
  margin-top: 25px;
  border-radius: 100%;
  background: #dedede;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  font-size: 18px;
  font-weight: 900;
}
@media screen and (max-width: 1366px) {
  .advert {
    width: 1300px;
  }
}


</style>
